import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { projects } from "../../data";


const Carousel = (photos: any) => {
  const images = photos.photos;
  // We will start by storing the index of the current image in the state.
  const [currentImage, setCurrentImage] = React.useState(0);
  // We are using react ref to 'tag' each of the images. Below will create an array of
  // objects with numbered keys. We will use those numbers (i) later to access a ref of a
  // specific image in this array.
  const refs = images.reduce((acc: { [x: string]: React.RefObject<unknown>; }, val: any, i: string | number) => {
    acc[i] = React.createRef();
    return acc;
  }, {});

  const scrollToImage = (i: any) => {
    
    setCurrentImage(i);
    // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
    // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
    // your current view! To do so we pass an index of the image, which is then use to identify our current
    // image's ref in 'refs' array above.
    refs[i].current.scrollIntoView({
      //     Defines the transition animation.
      behavior: 'smooth',
      //      Defines vertical alignment.
      block: 'nearest',
      //      Defines horizontal alignment.
      inline: 'start',
    });
  };

  // Some validation for checking the array length could be added if needed
  const totalImages = images.length;

  // Below functions will assure that after last image we'll scroll back to the start,
  // or another way round - first to last in previousImage method.
  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
  const arrowStyle = 'absolute text-white text-2xl z-10 bg-black h-10 w-10 rounded-full opacity-75 flex items-center justify-center';

  const sliderControl = (isLeft: string | boolean) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? 'left-2' : 'right-2'}`}
      style={{ top: '40%' }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? 'left' : 'right'}`}>
        {isLeft ? '◀' : '▶'}
      </span>
    </button>
  );

  return (
    <div className="p-2 flex justify-center w-screen w-auto md:w-auto items-center">
      <div className="relative w-full">
        <div className="carousel">
          {sliderControl(true)}
          {images.map((img: string, i: string | number) => (
            <div className="w-full flex-shrink-0" key={img} ref={refs[i]}>
              <img src={img} className="w-full object-contain" alt=""/>
            </div>
          ))}
          {sliderControl('')}
        </div>
      </div>
    </div>
  );
}



const gtagEvent = (ACTION: any, category: any, label: any, value: any) => {
  window.gtag("event", ACTION, {
      event_category: category,
      event_label: label,
      value: value
  })
}

export default function ProjectSections() {

  const [dataPo] = useState({
    name: '',
    date: '',
    subTitle: '',
    photos: [''],
    decription: '',
    link: ''
  });

  const openOverlay = (q:any) => {
    let ind:number = q-1;
    dataPo.name = projects[ind].name;
    dataPo.decription = projects[ind].descrition;
    dataPo.photos = projects[ind].photos;
    dataPo.link = projects[ind].link;
    dataPo.date = projects[ind].date;
    dataPo.subTitle = projects[ind].subTitle;
    setShowModal(true);
    gtagEvent("show",projects[ind].category,projects[ind].name,"");
  }

  const [showModal, setShowModal] = React.useState(false);

  return (
   <section className="text-gray-400 body-font">
     
<div className="container mx-auto px-6 py-24">     
  

  <div className="flex flex-col text-center w-full mb-12">
          <h2 className="text-2xl font-medium title-font mb-2  tracking-widest" >My Recent Work</h2>
  </div>

  <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6">
  
      {projects.map((d) => (
       
        <div className="bg-white w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden" key={d.id} onClick={() => openOverlay(d.id)}>
          <div className="flex items-end justify-end h-60 w-full bg-cover" style={{backgroundImage: d.bgThumb}}>
              <button className="p-2 rounded-full bg-blue-dark text-white mx-5 -mb-4 hover:bg-blue-500 focus:outline-none focus:bg-blue-500">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  {d.category === "web" &&            
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  }
                  {d.category === "mobile" &&
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  } 
                </svg>
              </button>
          </div>
          <div className="px-5 py-3">
              <h3 className="text-blue-dark uppercase">{d.name}</h3>
              <span className="text-gray-500 mt-2">{d.date} / <small>{d.subTitle}</small></span>
          </div>
      </div>    
      ))}

  </div>
  <div className="my-4 flex items-center justify-center">
    <Link to={'/projects'} className="inline-block text-center m-auto bg-green-dark rounded-md py-3 px-8 font-medium text-white hover:bg-green">
      More projects
    </Link>
  </div>
</div>

{showModal ? (
  <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-screen my-6 mx-auto max-w-3xl">
        
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          
          <div className="flex items-start justify-between p-5 rounded-t">
            
            <div>
              <h4 className="text-2xl text-blue-dark uppercase">{dataPo.name} </h4> 
              <div className="text-gray-500">{dataPo.date} / <small>{dataPo.subTitle}</small></div>
            </div>
            
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={() => setShowModal(false)}>
              <span className="bg-transparent text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          

          <div className="relative flex-auto m-1">
            <Carousel photos={dataPo.photos} />
          </div>

          <div className="relative flex-auto p-2 max-h-20 sm:max-h-full overflow-y-scroll">
            <p className="my-4 text-gray-500 text-sm">
              {dataPo.decription}
            </p>
          </div>
          
          <div className="flex items-center justify-end p-6 ">
            <button
              className="text-gray-300 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            <Link to={{pathname: dataPo.link }} target="_blank" className="bg-green-dark text-white active:bg-green font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
              View Website
            </Link>             
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </>
) : null}

</section>
)
}
