import JavascriptIcon from 'simple-icons/icons/javascript';
import Css3Icon from 'simple-icons/icons/css3';
import HtlmIcon from 'simple-icons/icons/html5';
import PhpIcon from 'simple-icons/icons/php';
import JavaIcon from 'simple-icons/icons/java';
import SqliteIcon from 'simple-icons/icons/sqlite';
import PythonIcon from 'simple-icons/icons/python';

import NodedotjsIcon from 'simple-icons/icons/nodedotjs';
import ReactIcon from 'simple-icons/icons/react';
import GithubIcon from 'simple-icons/icons/github';
import GithubactionsIcon from 'simple-icons/icons/githubactions';
import GitIcon from 'simple-icons/icons/git';
import MysqlIcon from 'simple-icons/icons/mysql';
import GoogleAdsenseIcon from 'simple-icons/icons/googleadsense';
import SpringBootIcon from 'simple-icons/icons/springboot';
import TailwindIcon from 'simple-icons/icons/tailwindcss';
import MaterialIcon from 'simple-icons/icons/materialui';
import TypeScriptIcon from 'simple-icons/icons/typescript';
import NpmIcon from 'simple-icons/icons/npm';
import angularIcon from 'simple-icons/icons/angular';
import bootstrapIcon from 'simple-icons/icons/bootstrap';
import amazonawsIcon from 'simple-icons/icons/amazonaws';
import ionicIcon from 'simple-icons/icons/ionic';
import xcodeIcon from 'simple-icons/icons/xcode';
import AndroidStudioIcon from 'simple-icons/icons/androidstudio';
import PostgreSQLIcon from 'simple-icons/icons/postgresql';
import AdobeCreativeCloudIcon from 'simple-icons/icons/adobecreativecloud';


export default function SkillsSections() {
  return (
    <section className="text-gray-400 body-font bg-blue-900">

      <div className="container flex flex-wrap px-5 pt-10 pb-10 mx-auto items-center">
        <div className="flex flex-col text-center w-full mb-12">
          <h2 className="text-2xl font-medium title-font mb-2 text-gray tracking-widest">SKILLS & EXPERIENCE</h2>
        </div>

        <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 md:mb-0 mb-10 pb-10 border-b border-gray-800">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">What I do?</h1>
          <p className="leading-relaxed text-base">
          Having embarked on my journey as a Full-Stack Developer nearly 18 years ago, I have had the privilege of working for various agencies, tech companies, and collaborating with exceptional individuals to craft digital products tailored for both business and consumer applications. My demeanor is one of quiet confidence, fueled by a natural curiosity that drives me to continuously enhance my skills, tackling design challenges with determination and finesse, one problem at a time.
          </p>     
        </div>
        
         <div className="flex flex-col md:w-1/2 md:pl-12">
          <h2 className="text-xs text-blue-400 tracking-widest font-medium title-font mt-4 mb-4 text-center">PROGRAMING LANGUAGES</h2>
          <div className="flex flex-wrap -m-4 text-center">
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="30" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{JavascriptIcon.title}</title><path fill={'#'+JavascriptIcon.hex} d={JavascriptIcon.path}/>
              </svg>
              <p className="leading-relaxed">JAVASCRIPT</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
               <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{PhpIcon.title}</title><path fill={'#'+PhpIcon.hex} d={PhpIcon.path}/>
              </svg>
              <p className="leading-relaxed">PHP</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{JavaIcon.title}</title><path fill={'#'+JavaIcon.hex} d={JavaIcon.path}/>
              </svg>
              <p className="leading-relaxed">JAVA</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{SqliteIcon.title}</title><path fill={'#'+SqliteIcon.hex} d={SqliteIcon.path}/>
              </svg>
              <p className="leading-relaxed">SQL</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{HtlmIcon.title}</title><path fill={'#'+HtlmIcon.hex} d={HtlmIcon.path}/>
              </svg>
              <p className="leading-relaxed">HTML</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{Css3Icon.title}</title><path fill={'#'+Css3Icon.hex} d={Css3Icon.path}/>
              </svg>
              <p className="leading-relaxed">CSS</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{PythonIcon.title}</title><path fill={'#'+PythonIcon.hex} d={PythonIcon.path}/>
              </svg>
              <p className="leading-relaxed">Python</p>
            </div>

          </div>

          <h2 className="text-xs text-blue-400 tracking-widest font-medium title-font mt-12 mb-4 text-center">TECHNOLOGY STACK</h2>
          <div className="flex flex-wrap -m-4 text-center">
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{NodedotjsIcon.title}</title><path fill={'#'+NodedotjsIcon.hex} d={NodedotjsIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">NODEJS</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{ReactIcon.title}</title><path fill={'#'+ReactIcon.hex} d={ReactIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">REACT</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{GitIcon.title}</title><path fill={'#'+GitIcon.hex} d={GitIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">GIT</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="32" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{MysqlIcon.title}</title><path fill={'#'+MysqlIcon.hex} d={MysqlIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">MySQL</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{GithubIcon.title}</title><path d={GithubIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">GITHUB</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{GithubactionsIcon.title}</title><path fill={'#'+GithubactionsIcon.hex} d={GithubactionsIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">GITHUB ACTIONS</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{angularIcon.title}</title><path fill={'#'+angularIcon.hex} d={angularIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">ANGULAR</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{GoogleAdsenseIcon.title}</title><path fill={'#'+GoogleAdsenseIcon.hex} d={GoogleAdsenseIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">GOOGLE ADSENSE</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{SpringBootIcon.title}</title><path fill={'#'+SpringBootIcon.hex} d={SpringBootIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">Spring Boot</p>
            </div>
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{TailwindIcon.title}</title><path fill={'#'+TailwindIcon.hex} d={TailwindIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">TAILWINDCSS</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{MaterialIcon.title}</title><path fill={'#'+MaterialIcon.hex} d={MaterialIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">MATERIAL UI</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{TypeScriptIcon.title}</title><path fill={'#'+TypeScriptIcon.hex} d={TypeScriptIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">TYPESCRIPT</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{NpmIcon.title}</title><path fill={'#'+NpmIcon.hex} d={NpmIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">NPM</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{bootstrapIcon.title}</title><path fill={'#'+bootstrapIcon.hex} d={bootstrapIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">BOOTSTRAP</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{amazonawsIcon.title}</title><path fill={'#'+amazonawsIcon.hex} d={amazonawsIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">AMAZON AWS</p>
            </div>
            
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{ionicIcon.title}</title><path fill={'#'+ionicIcon.hex} d={ionicIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">Ionic</p>
            </div>
          
            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{xcodeIcon.title}</title><path fill={'#'+xcodeIcon.hex} d={xcodeIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">XCODE</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{AndroidStudioIcon.title}</title><path fill={'#'+AndroidStudioIcon.hex} d={AndroidStudioIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">Android Studio</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{PostgreSQLIcon.title}</title><path fill={'#'+PostgreSQLIcon.hex} d={PostgreSQLIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">PostgreSQL</p>
            </div>

            <div className="p-4 sm:w-1/4 w-1/2">
              <svg role="img" width="27" 
               className="fill-current text-white hover:text-blue-500 transition delay-100 inline-block" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <title>{AdobeCreativeCloudIcon.title}</title><path fill={'#'+AdobeCreativeCloudIcon.hex} d={AdobeCreativeCloudIcon.path}/>
              </svg>
              <p className="leading-relaxed text-xs">Adobe Creative Cloud</p>
            </div>
            
          </div>
        
        </div>
      </div>

    </section>
  )
}