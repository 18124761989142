import { Link } from 'react-router-dom';


import GithubIcon from 'simple-icons/icons/github';
import InstagramIcon from 'simple-icons/icons/instagram';
import TwitterIcon from 'simple-icons/icons/twitter';
import StackOverflowIcon from 'simple-icons/icons/stackoverflow';

export default function Hero() {
  return (
    <div className="bg-gradient-to-r from-blue-900 to-blue-800 overflow-hidden">
      <div className="pt-16 pb-24 sm:pt-24 sm:pb-24 md:pt-24 md:pb-24 lg:pt-5 lg:pb-6 xl:pt-28 xl:pb-24 2xl:pt-40 2xl:pb-40">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
          <span className="px-2 inline-flex text-2xl text-gray-900 font font-medium rounded-md bg-green-100">
            Daniel Montoya
          </span>
          <div className="sm:max-w-xl">
            <h1 className="text-4xl font font-extrabold tracking-tight text-white sm:text-6xl">
            Full-Stack Developer
            </h1>
            <p className="mt-4 text-xl text-gray-300">
            I craft elegantly simple designs and code, passionately dedicated to my craft.
            </p>
            <div className="mt-5">
                <button className="p-1 rounded-full focus:outline-none transition delay-100">
                  <a href="https://github.com/montoyab/" target="_blank" rel="noopener noreferrer">
                    <svg role="img" width="28" className="fill-current text-white hover:text-green transition delay-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>{GithubIcon.title}</title><path d={GithubIcon.path}/></svg>
                  </a>
                </button>
                <button className="p-1 rounded-full focus:outline-none transition delay-100">
                  <a href="https://twitter.com/montoyab1" target="_blank" rel="noopener noreferrer">
                    <svg role="img" width="28" className="fill-current text-white hover:text-green transition delay-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>{TwitterIcon.title}</title><path d={TwitterIcon.path}/></svg>
                  </a>
                </button>
                <button className="p-1 rounded-full focus:outline-none transition delay-100">
                  <a href="https://stackoverflow.com/cv/montoyab" target="_blank" rel="noopener noreferrer">
                    <svg role="img" width="28" className="fill-current text-white hover:text-green transition delay-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>{StackOverflowIcon.title}</title><path d={StackOverflowIcon.path}/></svg>
                  </a>
                </button>
                <button className="p-1 rounded-full focus:outline-none transition delay-100">
                  <a href="https://www.instagram.com/montoyab/" target="_blank" rel="noopener noreferrer">
                    <svg role="img" width="28" className="fill-current text-white hover:text-green transition delay-100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>{InstagramIcon.title}</title><path d={InstagramIcon.path}/></svg>
                  </a>
                </button>
                
            </div>
          </div>
          <div>
            <div className="mt-10">
              <Link
                to={{pathname: "https://portafolioam.s3.amazonaws.com/pdf/alejandro-montoya-resume.pdf" }}
                target="_blank"
                className="inline-block text-center bg-gradient-to-r from-green-light to-green-dark rounded-md py-3 px-8 font-medium text-white hover:from-green-dark hover:to-green-light"
              >
                Resume
              </Link>
              <Link
                to="/projects"
                className="inline-block text-center ml-2 bg-blue-dark rounded-md py-3 px-8 font-medium text-white hover:bg-blue-light hover:text-blue"
              >
                Projects
              </Link>
              <div className="absolute xl:w-1/2 lg:w-1/2 xl:top-24 lg:top-20 inset-y-0 right-0">
                <img
                  className="hidden lg:block"
                  src={process.env.PUBLIC_URL + '/img/hero.png'}
                  alt="background hero"
                  height="500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
