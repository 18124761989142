import LayoutMain from '../component/layout/Main';
import Contact from '../component/sections/Contact';
import Footer from '../component/sections/Footer';

window.gtag("config", "G-70P2TW0548", {
  page_title: "Contact",
  page_path: "/contact",
});

export default function PageContact() {
  
    return (
      <LayoutMain>
        <Contact />
        <Footer />
      </LayoutMain>
    )
  }