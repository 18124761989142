export const projects= [
    {
      id: 1,
      name:"Foreclosure.com",
      date:"2007 - 2020",
      subTitle:"Front-End Developent",
      category:"web",
      descrition:"While working in Active Data Technologies, my team and I were asked to come up with a re-design for foreclosure.com and maintaining. Multiple areas needed to be addressed in order to improve user experience, bring awareness of features, increase lead conversions and make the website responsive. I was assigned the task of redesigning the user interface and information architecture of the website, while other members of my team worked on the backend. with HTML, Spring, Java, Freemarker, PostgreSQL and Javascript",
      thumb: "/img/foreclosure-thumb.png",
      bgThumb: "url('/img/foreclosure-thumb.png')",
      photos: ["/img/foreclosure-web-1.jpg","/img/foreclosure-web-2.jpg","/img/foreclosure-web-3.jpg","/img/foreclosure-web-5.jpg"],
      link: "https://www.foreclosure.com",
    },
    {
        id: 2,
        name:"Foreclosure App",
        date:"2014 - 2018",
        subTitle:"App Developent",
        category:"mobile",
        descrition:"Wored developing, testing, and launching this mobile application for foreclosure.com destined for mobile devices, such as smartphones or tablets. They are developed on iOS and Android platforms.",
        thumb: "/img/foreclosure-app-thumb.png",
        bgThumb: "url('/img/foreclosure-app-thumb.png')",
        photos: ["/img/foreclosure-app-1.jpg","/img/foreclosure-app-2.jpg"],
        link: "https://www.foreclosure.com",
      },
      {
        id: 3,
        name:"Homesteps.com",
        date:"2011 - 2021",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"My team and I were asked to come up with a re-design for Homesteps.com and maintaining. Multiple areas needed to be addressed in order to improve user experience, bring awareness of features, increase lead conversions and make the website responsive.",
        thumb: "/img/homesteps-thumb.png",
        bgThumb: "url('/img/homesteps-thumb.png')",
        photos: ["/img/homesteps-web-1.jpg","/img/homesteps-web-2.jpg","/img/homesteps-web-3.jpg","/img/homesteps-web-4.jpg"],
        link: "https://www.homesteps.com/",
      },
      {
        id: 4,
        name:"Homesteps App",
        date:"2015 - 2021",
        subTitle:"App Developent",
        category:"mobile",
        descrition:"Wored designing, developing, testing, and launching this mobile application destined for mobile devices, such as smartphones or tablets. On searching for propertied form Homesteps. They are developed on iOS and Android platforms.",
        thumb: "/img/homesteps-app-thumb.png",
        bgThumb: "url('/img/homesteps-app-thumb.png')",
        photos: ["/img/homesteps-app-1.jpg","/img/homesteps-app-2.jpg"],
        link: "https://www.homesteps.com/",
      },
      {
        id: 5,
        name:"homepath.com",
        date:"2009 - 2020",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"We started by assessing the effectiveness of the HomePath website and how homebuyers use the site’s various sections. With a thorough understanding of how users utilize the site, we were able to create a specialized series of tactics to increase awareness and overcome the perceived limitations and user concerns regarding properties owned by entities like Fannie Mae.",
        thumb: "/img/homepath-thumb.png",
        bgThumb: "url('/img/homepath-thumb.png')",
        photos: ["/img/homepath-web-1.jpg","/img/homepath-web-2.jpg","/img/homepath-web-3.jpg","/img/homepath-web-4.jpg"],
        link: "https://www.homepath.com/",
      },
      {
        id: 6,
        name:"homepath App",
        date:"2015 - 2020",
        subTitle:"App Developent",
        category:"mobile",
        descrition:"While working at Active Data Technologies, Fannie Mae approached us to design, develop and lauching  mobile application for HomePath with the goal to feature some of their foreclosed properties. Using the here.com map API whe developed that could map out an area around a specific location based on the desired driving time from said location and Fannie Mae wanted to use it.  Users had the option of viewing results as a list or on a map and an admin dashboard was created to review properties saved and to manage email alerts. They are developed on iOS and Android platforms.",
        thumb: "/img/homepath-app-thumb.png",
        bgThumb: "url('/img/homepath-app-thumb.png')",
        photos: ["/img/homepath-app-1.jpg","/img/homepath-app-2.jpg","/img/homepath-app-3.jpg","/img/homepath-app-4.jpg"],
        link: "https://www.homepath.com/",
      },
      {
        id: 7,
        name:"cityvisionhomes.com",
        date:"2020 - 2021",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"Wored designing, developing, testing this web site done with Angular.",
        thumb: "/img/cityvision-thumb.png",
        bgThumb: "url('/img/cityvision-thumb.png')",
        photos: ["/img/cityvision-web-1.jpg","/img/cityvision-web-2.jpg","/img/cityvision-web-3.jpg","/img/cityvision-web-4.jpg","/img/cityvision-web-5.jpg"],
        link: "https://www.cityvisionhomes.com/",
      },
      {
        id: 8,
        name:"oceantitle.com",
        date:"2020 - 2021",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"Developed and maintained this website.",
        thumb: "/img/oceantitle-thumb.png",
        bgThumb: "url('/img/oceantitle-thumb.png')",
        photos: ["/img/oceantitle-web-1.jpg","/img/oceantitle-web-2.jpg","/img/oceantitle-web-3.jpg","/img/oceantitle-web-4.jpg"],
        link: "https://www.oceantitle.com/",
      },
      {
        id: 9,
        name:"bkginc.com",
        date:"2020 - 2021",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"Programmed this website for BK Global® is a national company with a bankruptcy service platform consisting of a network of certified bankruptcy real estate Brokers and Agents, an online marketplace for listing. ",
        thumb: "/img/bkginc-thumb.png",
        bgThumb: "url('/img/bkginc-thumb.png')",
        photos: ["/img/bkginc-web-1.jpg","/img/bkginc-web-2.jpg","/img/bkginc-web-3.jpg","/img/bkginc-web-4.jpg","/img/bkginc-web-5.jpg"],
        link: "https://www.bkginc.com/",
      },
      {
        id: 10,
        name:"renttoownhomes.cc",
        date:"2018 - 2019",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"This is one off the example done for foreclosure.com as a Private Label Websites.",
        thumb: "/img/renttoownhomes-thumb.png",
        bgThumb: "url('/img/renttoownhomes-thumb.png')",
        photos: ["/img/renttoownhomes-web-1.jpg","/img/renttoownhomes-web-2.jpg","/img/renttoownhomes-web-3.jpg","/img/renttoownhomes-web-4.jpg","/img/renttoownhomes-web-5.jpg"],
        link: "https://www.renttoownhomes.cc/",
      },
      {
        id: 11,
        name:"clearfruitwater",
        date:"2015",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"Programmed this websites on wordpress for National Beverage Corp asantewater.com, spreesparklingwater.com, clearfruitwater.com, everfreshjuice.com & ripitenergy.com. ",
        thumb: "/img/clearfruitwater-thumb.png",
        bgThumb: "url('/img/clearfruitwater-thumb.png')",
        photos: ["/img/clearfruitwater-web-1.jpg","/img/clearfruitwater-web-2.jpg","/img/clearfruitwater-web-3.jpg"],
        link: "https://www.clearfruitwater.com/",
      },
      {
        id: 12,
        name:"isabeldeobaldia.com",
        date:"2012 - 2013",
        subTitle:"Front-End Developent",
        category:"web",
        descrition:"Artist Isabel De Obaldia asked me to design her website. She was very interested in showcasing her work which includes sculpture, paintings and drawings and prints; as well as including information about events, gallery openings and news. The result is a website that is easy to navigate, very clean and organized, exactly what the artist wanted. with wordpress, html, css javascript and php",
        thumb: "/img/isabeldeobaldia-thumb.png",
        bgThumb: "url('/img/isabeldeobaldia-thumb.png')",
        photos: ["/img/isabeldeobaldia-web-1.jpg","/img/isabeldeobaldia-web-2.jpg","/img/isabeldeobaldia-web-3.jpg","/img/isabeldeobaldia-web-4.jpg"],
        link: "https://www.isabeldeobaldia.com/",
      }
  ];