import LayoutMain from '../component/layout/Main';
import Skills from '../component/sections/Skills';
import Footer from '../component/sections/Footer';

window.gtag("config", "G-70P2TW0548", {
  page_title: "Skills",
  page_path: "/skills",
});
export default function PageSkills() {
  
  return (
      <LayoutMain>
       <Skills />
       <Footer />
      </LayoutMain>
    )
  }