import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/outline';
import axios from "axios";

require('dotenv').config();

export default function ContactSections() {
  
  
  
  const [data, setData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef(0);

  useEffect(() => {
    clearTimeout(timer.current);

  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }
  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  const submitForm = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!data.name || !data.email || !data.message) return alert('Add your information to be able to contact.');

    if (!loading) {

      setLoading(true);

      timer.current = window.setTimeout(async () => {
        setLoading(false);
        setSuccess(true);
        
        const myHeaders = new Headers();
            // add content type header to object
        myHeaders.append("Content-Type", "application/json");
        const raw = JSON.stringify({"name":data.name,"email":data.email,"messaje":data.message});

        // make API call with parameters and use promises to get response
        await fetch("https://mqvwkrqqh8.execute-api.us-east-1.amazonaws.com/dev/", {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        })
        .then(response => response.text())
        .then(result => alert(JSON.parse(result).body))
        .catch(error => console.log('error', error));
  
      }, 3000);

    }

  }

  return (
    <section className="text-gray-400 bg-white body-font relative">

      <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">

        <div className="lg:w-2/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-2xl font-medium title-font mb-2 text-gray-800 tracking-widest">CONTACT ME</h2>
          <p className="lg:w-2/3  leading-relaxed text-base mb-6">Let's develop good projects together!</p>
          <form className="row" onSubmit={submitForm}>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-400">Name</label>

              <input type="text" id="name" name="name" onChange={handleInputChange} className="w-full rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"></input>
            </div>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-400">Email</label>
              <input type="email" id="email" name="email" onChange={handleInputChange} className="w-full rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-gray-900 text-base outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"></input>
            </div>
            <div className="relative mb-4">
              <label className="leading-7 text-sm text-gray-400">Message</label>
              <textarea id="message" name="message" onChange={handleTextAreaChange} className="w-full rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-gray-900 h-32 text-base outline-none text-gray-900 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
            </div>

            <button type="submit" className="text-white w-full bg-green-dark border-0 py-2 px-6 focus:outline-none hover:bg-green rounded text-lg">
              {!success && !loading && 'Send'}
              {loading && 'Sending ...'}
              {success && <CheckIcon className="h-6 w-6 text-green-500 m-auto" aria-hidden="true" />}
            </button>

          </form>
        </div>

        <div className="lg:w-2/3 md:w-1/2 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">

          <div className="relative flex flex-auto py-6 rounded shadow-md">
            <div className="lg:w-1/2 px-6 mb-4">
              <h2 className="title-font font-semibold text-gray tracking-widest text-xs">Resume</h2>
              <a href="https://portafolioam.s3.amazonaws.com/pdf/alejandro-montoya-resume.pdf" className="text-blue leading-relaxed" target="_blank" rel="noopener noreferrer">Alejandro Montoya</a>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-gray tracking-widest text-xs">EMAIL</h2>
              <a href="mailto:montoyab1@gmail.com" className="text-blue leading-relaxed">montoyab1@gmail.com</a>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}