import LayoutMain from '../component/layout/Main';
import Hero from '../component/sections/Hero';
import Projects from '../component/sections/Projects';
import Skills from '../component/sections/Skills';
import Contact from '../component/sections/Contact';
import Footer from '../component/sections/Footer';

window.gtag("config", "G-70P2TW0548", {
  page_title: "Home",
  page_path: "/",
});

export default function Home() {
    

    return (
      <LayoutMain>
       <Hero />
       <Projects />
       <Skills />
       <Contact />
       <Footer />
      </LayoutMain>
    )
  }