import LayoutMain from '../component/layout/Main';
import Projects from '../component/sections/Projects';
import Footer from '../component/sections/Footer';
window.gtag("config", "G-70P2TW0548", {
  page_title: "Projects",
  page_path: "/projects",
});

export default function PageProjects() {
 
  return (
      <LayoutMain>
       <div className="bg-gradient-to-r from-blue-900 to-blue-800 overflow-hidden">
       <Projects />
       </div>
       <Footer />
      </LayoutMain>
    )
  }